import type { BasePageContentAPI } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { BLOCKS } from '@contentful/rich-text-types'

const HOME_FALLBACK_DATA: BasePageContentAPI = {
  id: '3WflFt0AhP0k5agSAfqagd',
  name: 'home-duplicated',
  blocks: [
    {
      id: '1z7u5BjdLo0JetCHIDdxUm',
      type: 'Carousel',
      props: {
        technicalName: 'Carousel - SWEDEN',
        slides: [
          {
            id: '1v8AdeWj4JXHdyfuD8Hhuq',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/568oK4SQd9jPHqI2Xp9CSz/72164cfac40082f5489b32bf5971121d/GENERIC_HPBANNERS_iPhone13_Desktop_SE.jpeg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5L67oUmGZJmQZOyySFrf8v/a9f9382821f44a33f29672e4eb52763c/GENERIC_HPBANNERS_iPhone13_Mobile_SE.jpeg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'SE - iPhone 13 x Generic',
            },
            link: {
              href: 'https://www.backmarket.se/sv-se/l/iphone-13/14c5d644-a43a-42a8-b8f3-37d5af3936fc',
            },
          },
          {
            id: '7h3V6hCCKAqfouYq3XJVZM',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/d6sT69Rn2kk6TRH7jUxqY/00da8fe2b273c85283c017dfc2291154/HP_Desktop-Swedish__1_.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/2Le1x1YCIYmlSjZjojKJHW/d1a330c17e1cf9762f90aa68c0e63cd1/HP_Mobile-Swedish__1_.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'SE- Good Deals ',
            },
            link: {
              href: 'https://www.backmarket.se/sv-se/l/bra-erbjudanden/be9b14f4-89e2-4315-8d3b-4d7d613b562f',
            },
          },
          {
            id: '6VZAR5zsFSxBPQTW6JTlVn',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3EGZiaWXAWfD4s6XR8Nirp/c3c6129b4a721d4028f9a22791e1e61a/Copy_of_VISION_Desktop_SE.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5QCnydDWx4lzo60M4tiQAx/f58d4095332869d16d6186db2458bb12/Copy_of_VISION_Mobile_SE.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'SE - Vision',
            },
            link: {
              href: 'https://www.backmarket.se/sv-se/about-us',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '7tRWdptLsoYNmK33VxeEMX',
      type: 'Categories',
      props: {
        title: 'Tungviktare',
        subtitle: 'Mer eftersökta på Google än Muhammad Ali.',
        categories: [
          {
            id: '7kR1w8lDyLO41NTaYrjKch',
            title: 'Mobiltelefoner',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6G8NfbciL3rtpnVeXho8BD/53581a10749742aade5511389994a7ff/290045_d7327841-d981-4566-b4a5-a392912a824f-thumb.jpg',
              width: 400,
              height: 400,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5opbjQ8sRrg3OeN3HI3kI3/25afa5692532b40c5d0c422c34cd1c58/290045_d7327841-d981-4566-b4a5-a392912a824f-thumb__1_.jpg',
              widthMobile: 200,
              heightMobile: 200,
              alt: 'iPhone 11 Pro',
            },
            tagline: 'Smartare än att köpa en ny (eller vad tycker du?)',
            link: {
              href: 'https://www.backmarket.se/sv-se/l/smartphones/6c290010-c0c2-47a4-b68a-ac2ec2b64dca',
            },
          },
          {
            id: '41jfOazCwBlFLTqHkchSy6',
            title: 'Apple-hörnan',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5VSagRpzOyIad06fpq9RSp/03258d28b9e1874d643684250a915f0d/imacUS.jpeg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iMac',
            },
            tagline: 'Apple-kvalitet till rejält lägre priser.',
            link: {
              href: 'https://www.backmarket.se/sv-se/l/apple/befd8e84-d318-48eb-88ef-362979a53183',
            },
          },
          {
            id: '3McYKQUCJrqPwmvwkCpzOE',
            title: 'Surfplattor',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3q7nQi6Q5LKO60xX5RPieH/38ea45b04b861eca9cdac6bf481dc5b2/ipad_pro_2019.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPad Pro',
            },
            tagline: 'Upp till 40 % rabatt – och pengar över till annat.',
            link: {
              href: 'https://www.backmarket.se/sv-se/l/surfplattor/5a3cfa21-b588-49b1-b4e9-2636bec68ada',
            },
          },
          {
            id: 'x3jVXfgdWICWnisB8hoFn',
            title: 'Apple Watch',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2coRI1LOw46qbxuVTi3bJY/4dd409b567e9eeb9abb225ccb5731679/AppleWatch6.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Apple Watch',
            },
            tagline: 'Kolla på videor på en mycket liten skärm.',
            link: {
              href: 'https://www.backmarket.se/sv-se/l/apple-watch/92caf545-c033-409f-bcb0-d3cfec8af49d',
            },
          },
          {
            id: '14R3peZcvD4eovzFQ1ybDa',
            title: 'MacBook',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6MZVE5kh4OulMTCQlbTuT8/b2193211c0c800866f73c6fa033e608d/MacBook_Pro_Touchbar.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'MacBook Pro TouchBar',
            },
            tagline: 'Se smartare ut när du dricker din cappuccino.',
            link: {
              href: 'https://www.backmarket.se/sv-se/l/alla-macbook/297f69c7-b41c-40dd-aa9b-93ab067eb691',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '7xoR1MMGMRWIApkvSP3vqF',
      type: 'Categories',
      props: {
        title: 'Andra kategorier',
        subtitle: 'Inget kastas bort – allt kan återanvändas.',
        categories: [
          {
            id: '3W9GCw77Wwn4SoNHi9nBI7',
            title: 'iPhone',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/13PFF8Xa3s6EiayLPC97g2/b98f088e61b030ffcb07f744fa5362c0/iphone_XR.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'US - iPhone XR',
            },
            tagline: 'De grönaste äpplena på marknaden.',
            link: {
              href: 'https://www.backmarket.se/sv-se/l/iphone/aabc736a-cb66-4ac0-a3b7-0f449781ed39',
            },
          },
          {
            id: '3CxUfl26UW8fwWJlGb5YSS',
            title: 'Android-mobiltelefoner',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/c3phFqsPsCEl7dJiGDX5d/480ba300ab65869a637c24c81c771d22/S20STORE.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Samsung s20',
            },
            tagline: 'Eftersom det finns andra frukter än äpplen.',
            link: {
              href: 'https://www.backmarket.se/sv-se/l/smartphone-samsung/99760870-ed75-482f-a626-2b4f964c55ae',
            },
          },
          {
            id: '2Q6MKBSA5GZgytqKJDdp2g',
            title: 'Ljud',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6UBdFdF7X6S0nGCexTB9pw/d0ff942a61ecce5148a38165576a6bfc/308532_7b427385-34fc-40f5-9e68-73890ed77684-thumb.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'AirPods & Earphones',
            },
            tagline: 'Priser som är som musik för dina öron.',
            link: {
              href: 'https://www.backmarket.se/sv-se/l/audio-horlurar-hogtalare/6a6b4d5a-c50d-4a61-9a7a-3c3b1ce00e50',
            },
          },
          {
            id: '5f5RvqBbAmBe56AFZD62eI',
            title: 'Bärbara datorer',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4rah4soJKbLIZaX0YrEIfQ/f7374566b327242d0efe3f3f5a871b37/Asus_Zen_book_Pro.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Laptop windows',
            },
            tagline: 'När telefonskärmen inte räcker till.',
            link: {
              href: 'https://www.backmarket.se/sv-se/l/barbar-dator/630dab14-5051-49b9-bc7b-bb20876d4850',
            },
          },
          {
            id: '3ISTYy0auyc9wKvv2UiF1y',
            title: 'Kameror',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/51pMifL5BxMUDHSVwyAeBE/3619af382521fced433f847c6863e8e7/129798_a2cc9fc1-07fb-47cf-8613-ff82a354a3f8-thumb.jpg',
              width: 400,
              height: 400,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5T5ucA4BO8D0FC6BUnYB8a/df66403282e14ba1241e568aaa5e31a3/esfNikon.jpeg',
              widthMobile: 200,
              heightMobile: 200,
              alt: 'ES- BF Nikon',
            },
            tagline: 'Spara de viktigaste minnena.',
            link: {
              href: 'https://www.backmarket.se/sv-se/l/foto-kamera/07f6ddc5-ec6d-47d1-9ae8-4fe5e6e53c19',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '9fLERRoPqEdH204D78sFw',
      type: 'Faq',
      props: {
        title: 'Vad mer?',
        subtitle: 'Fyra frågor som vi alltid får',
        questions: [
          {
            id: '5g6lB3dr8qW0NImUOZp9OO',
            title: 'Hur vet jag att min enhet kommer att hålla?',
            text: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value:
                        'Med allt från screeningprocesser för alla säljare innan produkterna läggs ut för försäljning till hemliga beställningar som görs av Back Markets personal (naturligtvis under topphemliga kodnamn) ger vi 110 % för att se till att elektroniken vi erbjuder håller vad vi lovar. Dessutom kommer alla enheter med 30 dagars ångerrätt och 1 års garanti.',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: BLOCKS.PARAGRAPH,
                },
              ],
              nodeType: BLOCKS.DOCUMENT,
            },
          },
          {
            id: '6d6Fs7exKD06GmvgKn5pBI',
            title: 'Vad är skillnaden mellan rekonditionerad och ny?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'På utsidan ser en rekonditionerad smartphone ut (och fungerar) som ny. Men det är insidan som verkligen räknas. Rekonditionerad elektronik – smartphones, surfplattor, laptops och datorer – har mycket mindre miljöpåverkan än ny elektronik.\n\n',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '102hM9h7TMQySrva8sC83S',
            title: 'Vänta lite … vad säljer ni mer egentligen?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Du kan vara schyst mot planeten på många olika sätt, och när det kommer till elektronik är vi mer än en snygg smartphone. Vi har det mesta, från spelkonsoler till locktänger, babymonitorer till espressomaskiner (hej alla nyblivna föräldrar).',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: 'bWgzQ2dkkWROmEpiajobp',
            title:
              'Var T-800 i Terminator 2: Judgment Day (1991) tekniskt sett ”rekonditionerad”?',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Skynet skulle såklart behöva genomgå vår certifieringsprocess innan vi kan ge ett definitivt svar – men som Arnold skulle säga: "I\'ll be back".',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
        rendering: 'HTML_ONLY',
        ctaLink: {
          link: {
            href: 'https://www.backmarket.se/sv-se/help',
          },
          label: 'Hitta svar',
        },
      },
      hiddenDevices: [],
    },
  ],
  type: 'core',
  title: 'Start',
  meta: {
    title: 'Din rekonditionerade (stor) marknad',
    description:
      'Hitta de bästa erbjudandena på rekonditionerade telefoner, bärbara datorer och enheter hos Back Market. Upp till 70% billigare priser. Fri frakt, 12 mån garanti, 30 dagars returrätt.',
    image: {
      src: 'https://images.ctfassets.net/mmeshd7gafk1/5BDjAiB7WVkFJcE5MXLNy4/6a1e6f56f5be59cd0d38ee4c721d05c4/SEO-Website-Thumbnail.png',
      width: 516,
      height: 258,
      srcMobile: '',
      widthMobile: 0,
      heightMobile: 0,
      alt: '',
    },
    alternateLinks: [
      {
        country: 'FR',
        locale: 'fr-FR',
      },
      {
        country: 'DE',
        locale: 'de-DE',
      },
      {
        country: 'BE',
        locale: 'fr-BE',
      },
      {
        country: 'ES',
        locale: 'es-ES',
      },
      {
        country: 'IT',
        locale: 'it-IT',
      },
      {
        country: 'GB',
        locale: 'en-GB',
      },
      {
        country: 'AT',
        locale: 'de-AT',
      },
      {
        country: 'NL',
        locale: 'nl-NL',
      },
      {
        country: 'US',
        locale: 'en-US',
      },
      {
        country: 'FI',
        locale: 'fi-FI',
      },
      {
        country: 'PT',
        locale: 'pt-PT',
      },
      {
        country: 'IE',
        locale: 'en-IE',
      },
      {
        country: 'GR',
        locale: 'el-GR',
      },
      {
        country: 'SK',
        locale: 'sk-SK',
      },
      {
        country: 'SE',
        locale: 'sv-SE',
      },
      {
        country: 'JP',
        locale: 'ja-JP',
      },
      {
        country: 'AU',
        locale: 'en-AU',
      },
    ],
  },
  badge: '',
  titleSeo: 'Din rekonditionerade (stor) marknad',
  titleBreadcrumb: 'Home',
  banner: {},
  textSeo: null,
  hideFromSearchEngine: false,
  parentPageInfos: null,
  pageType: 'core',
  pageName: 'home-duplicated',
}

export default HOME_FALLBACK_DATA
